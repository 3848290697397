<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-spinner v-if="isloading" />
          <div v-if="error">
            <mdb-alert color="danger">{{error}}</mdb-alert>
          </div>
          <mdb-container fluid v-if="!isloading && result">
            <mdb-row>
              <mdb-col>
                <h1>{{$t('searchengine.booking')}} {{result.pnr}}</h1>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col xl="6" md="6">
                <h3>{{$t('searchengine.passengers')}}</h3>
                <mdb-card class="mb-4" v-for="(pax, index) in result.paxes" v-bind:key="pax.id">
                  <mdb-card-header :color="getColorForPax(index)">
                    <a v-on:click="togglepaxopen(pax.id)">
                      {{pax.firstname}} {{pax.middlename}} {{pax.lastname}} ({{pax.birthdate}})
                      <span v-if="pax.deleted_at !== null" class="dark-grey-text">
                        <strong>{{$t('searchengine.deleted')}}</strong>
                      </span>
                      <mdb-icon v-if="paxopenstate[pax.id]==='open'" size="lg" icon="chevron-down" class="float-right" />
                      <mdb-icon v-else size="lg" icon="chevron-up" class="float-right" />
                    </a>
                  </mdb-card-header>
                  <mdb-card-body v-if="paxopenstate[pax.id]==='open'">
                    <mdb-tbl striped>
                      <mdb-tbl-body>
                        <tr>
                          <td>{{$t('searchengine.added_at')}}</td>
                          <td>{{noteDateFormat(pax.created_at)}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.gender')}}</td>
                          <td>{{pax.gender==='F'?$t('searchengine.woman'):$t('searchengine.man')}} ({{pax.gender}})</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.birthdate')}}</td>
                          <td>{{pax.birthdate}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.email')}}</td>
                          <td>{{pax.email}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.phone_number')}}</td>
                          <td>{{pax.telephone}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.seat')}}</td>
                          <td>{{pax.seat}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.ssr')}}</td>
                          <td>{{pax.ssr}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.additionalbag')}}</td>
                          <td>{{pax.additionalbag}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.secondbag')}}</td>
                          <td>{{pax.secondbag}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.owtoszbag')}}</td>
                          <td>{{pax.owtoszbag}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.selectclass')}}</td>
                          <td>{{pax.selectclass}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('searchengine.ife')}}</td>
                          <td>{{pax.ife}}</td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col xl="6" md="6">
                <h3>{{$t('searchengine.flights')}}</h3>
                <mdb-card class="mb-4">
                  <mdb-card-header color="red darken-3">&nbsp;</mdb-card-header>
                  <mdb-card-body>
                    <mdb-row v-for="flight in orderedsegments" v-bind:key="flight.id" class="border-top pt-2 mt-1">
                      <mdb-col class="mt-4">
                        {{flight.flightnum}}
                      </mdb-col>
                      <mdb-col>
                        <strong>{{flight.depgateway}}</strong>
                        <br />
                        {{getDate(flight.depdate)}} <br />{{getTime(flight.depdate)}}
                      </mdb-col>
                      <mdb-col class="mt-3">
                        <mdb-icon size="2x" class="grey-text" icon="plane" />
                      </mdb-col>
                      <mdb-col>
                        <strong>{{flight.arrgateway}}</strong>
                        <br />
                        {{getDate(flight.arrdate)}} <br />{{getTime(flight.arrdate)}}
                      </mdb-col>
                    </mdb-row>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mb-4">
                  <mdb-card-header color="grey darken-3">{{$t('searchengine.notes')}}</mdb-card-header>
                  <mdb-card-body>
                    <div v-for="note in notes" v-bind:key="note.id">
                      <mdb-row>
                        <mdb-col xl="4">
                          <div class="mb-3">
                            {{$t('searchengine.added_at')}} {{noteDateFormat(note.created_at)}}
                          </div>
                          <div class="mb-3">
                            {{$t('searchengine.by')}}: {{note.added_by.name}}
                          </div>
                          <div class="mb-3">
                            {{$t('searchengine.for')}}: {{note.pax.firstname}} {{note.pax.lastname}}
                          </div>
                        </mdb-col>
                        <mdb-col xl="8" v-html="nl2br(note.content)"></mdb-col>
                      </mdb-row>
                      <hr />
                    </div>
                    <h3 v-if="notes.length === 0">{{$t('searchengine.no_note')}}</h3>
                    <mdb-row class="mt-4">
                      <mdb-col md="12">
                        <mdb-btn class="float-right" outline="elegant" v-on:click="shownewnotemodal = true">{{$t('searchengine.new_note')}}</mdb-btn>
                      </mdb-col>
                    </mdb-row>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-modal :show="shownewnotemodal" @close="shownewnotemodal = false" size="lg" class="text-center" info centered>
              <mdb-modal-header center :close="false">
                <p class="heading">{{$t('searchengine.new_note')}}</p>
              </mdb-modal-header>
              <mdb-modal-body >
                <mdb-select
                  color="primary"
                  v-model="availablepaxes"
                  :label="$t('searchengine.for_passenger')"
                  v-on:getValue="getSelectedValuePax"
                  :visibleOptions=10
                />
                <mdb-input type="textarea" v-model="newnotecontent.content" label="Note" :rows="15" />
              </mdb-modal-body>
              <mdb-modal-footer center>
                <mdb-btn color="info" v-on:click="saveNewNote()">{{$t('searchengine.save')}}</mdb-btn>
                <mdb-btn outline="info" @click="shownewnotemodal = false">{{$t('searchengine.cancel')}}</mdb-btn>
              </mdb-modal-footer>
            </mdb-modal>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbSpinner,
  mdbAlert,
  mdbTbl,
  mdbTblBody,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbSelect,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'holasunflightdetails',
  data() {
    return {
      error: null,
      success: null,
      isloading: true,
      searchenginedata: {
        booking_num: '',
        firstname: '',
        lastname: '',
      },
      availablepaxes: [],
      paxopenstate: [],
      result: null,
      notes: [],
      shownewnotemodal: false,
      newnotecontent: {
        paxid: null,
        content: '',
      },
    };
  },
  computed: {
    orderedsegments() {
      const flights = [];
      this.result.flights.forEach((flight) => {
        flights.push(flight);
      });
      function compare(a, b) {
        if (new Date(a.depdate).getTime() < new Date(b.depdate).getTime()) {
          return -1;
        }
        if (new Date(a.depdate).getTime() > new Date(b.depdate).getTime()) {
          return 1;
        }
        return 0;
      }

      return flights.sort(compare);
    },
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbSpinner,
    mdbAlert,
    mdbTbl,
    mdbTblBody,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbSelect,
  },
  mounted() {
    this.loadbooking();
  },
  methods: {
    togglepaxopen(paxid) {
      if (this.paxopenstate[paxid] === 'close') {
        this.paxopenstate[paxid] = 'open';
      } else {
        this.paxopenstate[paxid] = 'close';
      }
      this.$forceUpdate();
    },
    loadbooking() {
      this.isloading = true;
      this.searchenginedata.booking_num = this.$route.params.pnr;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/holasun/search', { query: self.searchenginedata, includedeletedpaxes: true }).then((response) => {
        if (response.status === 'OK') {
          if (response.data.length) {
            self.result = response.data.pop();
            self.result.paxes.forEach((elm) => {
              self.paxopenstate[elm.id] = 'close';
              if (typeof elm.deleted_at !== 'string') { // façon bizarre de s'assurer que la valeur ne soit pas null
                self.availablepaxes.push({ text: `${elm.firstname} ${elm.lastname}`, value: elm.id });
              }
            });
            self.loadNotes();
          } else {
            self.error = this.$t('searchengine.booking_not_found');
          }
        } else {
          self.error = response.msg;
        }
        self.isloading = false;
      });
    },
    getDate(_thedate) {
      const thedate = new Date(_thedate);
      // thedate.setHours(0, 0, 0, 0);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getTime(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleTimeString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    noteDateFormat(elm) {
      const ladate = `${this.getNoteDate(elm)} ${this.getNoteTime(elm)}`;
      return ladate;
    },
    getNoteDate(timestamp) {
      const thedate = new Date(timestamp);
      thedate.setHours(0, 0, 0, 0);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getNoteTime(timestamp) {
      const thedate = new Date(timestamp);
      const options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleTimeString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    loadNotes() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/holasun/get-notes-for-pnr', { pnr: self.result.pnr }).then((response) => {
        if (response.status === 'OK') {
          self.notes = response.data;
        } else {
          self.error = response.msg;
        }
      });
    },
    getSelectedValuePax(val) {
      this.newnotecontent.paxid = val;
    },
    saveNewNote() {
      const self = this;
      const api = new Apicall();
      this.error = null;
      api.call('POST', 'api/holasun/add-notes-for-pnr', { note: self.newnotecontent }).then((response) => {
        if (response.status === 'OK') {
          self.newnotecontent.content = '';
          self.loadNotes();
        } else {
          self.error = response.msg;
          window.scrollTo(0, 0);
        }
        self.shownewnotemodal = false;
      });
    },
    nl2br(content) {
      return content.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    getColorForPax(paxid) {
      if (this.result.paxes[paxid].deleted_at !== null) {
        return 'red lighten-3';
      }
      return 'blue darken-3';
    },
  },
};
</script>
